import React from "react"
import { Layout } from "components/layout/Layout"
import { Meta } from "components/meta/Meta"
import { Hero } from "components/hero"
import astronautImg from "../assets/images/careers/astronaut.svg"
import { ButtonLink } from "../components/button"

const heroProps = {
  subtitle:
    "Not to worry, we have other jobs you can apply to. Click below to see all open job positions.",
  title: <>Whoops, seems like we found a Martian for this position!</>,
  button: (
    <>
      <ButtonLink variant="primary" to={"/careers/#openings"}>
        Open positions
      </ButtonLink>
    </>
  ),
}

const CareersInnerClosed = ({ pageContext, location }) => {
  const { job } = pageContext
  const ogDescription =
    job.jobDescriptions.ogDescription ||
    "Ready to discover Mars? Click and apply for the job position."

  const seoDescription =
    job.jobDescriptions.metaDescription || job.jobDescriptions.description

  return (
    <Layout location={location}>
      <Meta
        title={`Careers - ${job.name}`}
        description={ogDescription}
        seoDescription={seoDescription}
      />
      <Hero
        fullscreenWithFooter
        topImage={<img src={astronautImg} alt="Astronaut" />}
        {...heroProps}
      />
    </Layout>
  )
}

export default CareersInnerClosed
